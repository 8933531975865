import './RecentProject.scss';

const RecentProject = () => {
    return (
        <section className='recent-project'>
            <h1 className='title'>
                MOST RECENT PROJECT
            </h1>

            <div className='project'>
                <a href="https://www.hetdautenmannetje.be" target="_blank" rel="noopener noreferrer">
                <img
                    src='/images/dautenmannetje.png'
                    alt='Het Dautenmannetje'/>

                    <h2 className='dautenmannetje'>Het Dautenmannetje</h2>
                </a>
            </div>
        </section>
    );
}

export default RecentProject;
