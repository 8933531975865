import './App.scss';
import RecentProject from "./RecentProject";

function App() {
    return (
        <div>
            <header className='header'>
                <h1 className='title'>
                    <span className='first'>steve </span>BOTTELBERGS
                </h1>
                <p className='subtitle'>
                    Freelance front-end engineer <span className='separator'>//</span> Flutter developer <span className='separator'>//</span> World traveller
                </p>
            </header>
            <RecentProject/>
        </div>
    );
}

export default App;
